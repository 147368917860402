/*
 * @Description: 调拨申请mixins
 * @Author: LiangYiNing
 * @Date: 2021-12-17 14:53:55
<<<<<<< HEAD
 * @LastEditTime: 2023-03-16 11:29:29
=======
 * @LastEditTime: 2023-03-16 11:33:22
>>>>>>> fix-0316
 * @LastEditors: ChenXueLin
 */
import { printError } from "@/utils/util";
import {
  findDownList,
  examineEngineeringAllocationApply,
  examineAllocationApply,
  queryStockWarehouse,
  deleteAllocationApply,
  getCompanyList,
  revocationCheck
} from "@/api";
export default {
  data() {
    return {
      allocationApplyTypeList: [], //调拨类型
      allocationApplyStatusList: [], //调拨状态
      allocationExamineStatusList: [], //审核状态
      selColumnId: [], //选中的元素
      total: 0,
      loading: false,
      tableData: [], // 表格数据
      selectColumns: [],
      detailColumnData: [
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialTypeName",
          display: true,
          fieldLabel: "类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNum",
          display: true,
          fieldLabel: "申请数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "outMaterialNum",
          display: true,
          fieldLabel: "已出库数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "inMaterialNum",
          display: true,
          fieldLabel: "已入库数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNoStr",
          display: true,
          fieldLabel: "设备号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressCompanyNameStr",
          display: true,
          fieldLabel: "快递公司",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressNosStr",
          display: true,
          fieldLabel: "快递单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "outStockStatusName",
          display: true,
          fieldLabel: "调拨状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //明细表头
      addType: 1, //1新增2编辑3详情
      outSerialNumberSearchData: {},
      detailTableData: [],
      addVisible: false, //新增编辑弹框是否显示
      /**出库 */
      pickVisible: false, //发货弹框是否显示
      confirmVisible: false,
      inputVisible: false,
      editId: "",
      clickRow: {},
      applyNos: [],
      companyList: [],
      applyIdList: [], //发货出库的单据id
      inWarehouseList: [], //调入仓库
      outWarehouseList: [], //调出仓库
      rejectedVisible: false
    };
  },
  computed: {},
  watch: {
    //调出仓库
    "searchForm.outCompanyNo": {
      immediate: true,
      handler() {
        this.searchForm.outSerialNumber = "";
        this.searchForm.outWarehouseNo = "";
        this.queryStockWarehouseReq("out");
      }
    },
    "searchForm.inCompanyNo": {
      immediate: true,
      handler() {
        this.searchForm.inSerialNumber = "";
        this.searchForm.inWarehouseNo = "";
        this.queryStockWarehouseReq("in");
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      let promiseList = [
        findDownList([
          "allocationApplyType",
          "allocationApplyStatus",
          "allocationExamineStatus"
        ]),
        getCompanyList({})
      ];
      let [Res, companyRes] = await Promise.all(promiseList);
      //调拨类型下拉框
      this.allocationApplyTypeList = this.getFreezeResponse(Res, {
        path: "data.allocationApplyType"
      });
      //调拨状态下拉框
      this.allocationApplyStatusList = this.getFreezeResponse(Res, {
        path: "data.allocationApplyStatus"
      });
      //审核状态下拉框
      this.allocationExamineStatusList = this.getFreezeResponse(Res, {
        path: "data.allocationExamineStatus"
      });
      this.companyList = this.getFreezeResponse(companyRes, {
        path: "data"
      });
    },
    //查询仓库
    async queryStockWarehouseReq(type) {
      //in调入out调出
      try {
        let res = await queryStockWarehouse({
          organizationId:
            type == "in"
              ? this.searchForm.inCompanyNo
              : this.searchForm.outCompanyNo
        });
        if (type == "in") {
          this.inWarehouseList = res.data;
        } else {
          this.outWarehouseList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //重置
    handleReset() {
      this.$refs.searchForm.resetFields();
      this.timePickerResetList.map(item => {
        if (this.$refs[item]) this.$refs[item].reset();
      });
      this.searchForm.inWarehouseNo = "";
      this.searchForm.outWarehouseNo = "";
    },
    //格式化列表数据
    formatList(array) {
      array.map(item => {
        let countyName = item.countyName ? item.countyName : "";
        let address = item.address ? item.address : "";
        item.detailAddress =
          item.provinceName + item.cityName + countyName + address;
      });
      return array;
    },
    //获取明细
    async getDetailData(id) {
      if (!this.detailApi) return;
      try {
        let res = await this.detailApi({
          id: id ? id : this.selColumnId[this.selColumnId.length - 1]
        });
        if (res.code == "OK") {
          this.detailTableData = res.data;
          this.detailTableData.map(item => {
            item.equipNoStr = item.equipNoList.join(",");
            item.expressCompanyNameStr = item.expressCompanyNameList.join(",");
            item.expressNosStr = item.expressNos.join(",");
          });
        }
      } catch (error) {
        printError(error);
      }
    },
    getDetail(row) {
      this.getDetailData(row.applyId);
    },
    //点击行实现选中复选框
    handleRowClick(row, column) {
      if (column.type != "selection") {
        this.$refs.tableList.clearSelection();
        this.$refs.tableList.toggleRowSelection(row, true);
      }
      // if (!this.applyNos.includes(row.applyNo)) {
      //   this.$refs.tableList.toggleRowSelection(row, true);
      // } else {
      //   this.$refs.tableList.toggleRowSelection(row, false);
      // }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.applyNos = columns.map(item => {
        return item.applyNo;
      });
      this.selColumnId = columns.map(item => {
        return item.applyId;
      });
      this.selectColumns = columns;
      if (this.selColumnId.length) {
        this.getDetailData();
      } else {
        this.detailTableData = [];
      }
    },
    changeWarehouse(val, node, prop) {
      this.searchForm[prop] = node.warehouseNo;
    },
    handleAdd() {
      this.addType = 1;
      this.addVisible = true;
    },
    handleEdit(row) {
      this.editId = row.applyId;
      this.addVisible = true;
    },
    //点击出库
    handlePick() {
      if (!this.selectColumns.length) {
        this.$message.warning("请勾选数据");
        return;
      }
      // if (!this.selectColumns.every(item => item.applyStatus == "4025001")) {
      //   this.$message.warning("已发货的数据不能再次发货");
      //   return;
      // }
      let firstData = this.selectColumns[0];
      if (
        !this.selectColumns.every(
          item => item.contactName == firstData.contactName
        )
      ) {
        this.$message.warning("不同联系人不能同时批量出库");
        return;
      }
      if (
        !this.selectColumns.every(item => item.address == firstData.address)
      ) {
        this.$message.warning("不同联系地址不能同时批量出库");
        return;
      }
      if (this.selectColumns.some(item => item.examineStatus == "4026001")) {
        this.$message.warning("未审核的单据不能出库");
        return;
      }
      this.applyIdList = this.selectColumns.map(item => {
        return Object.assign(
          {},
          {
            id: item.outWarehouseApplyId,
            applyId: item.outWarehouseApplyId,
            versionNumber: item.versionNumber
          }
        );
      });
      this.pickVisible = true;
    },
    //点击确认入库
    handleReceive(row) {
      this.clickRow = row;
      this.confirmVisible = true;
    },
    //点击销审
    handleMarketingAudit() {
      if (!this.selColumnId.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.rejectedVisible = true;
    },
    confirm(revocationReason) {
      this.outRejectReq(revocationReason);
    },
    //销审请求
    async outRejectReq(revocationReason) {
      try {
        let res = await revocationCheck({
          applyIdList: this.selectColumns.map(item => {
            return Object.assign(
              {},
              {
                id: item.outWarehouseApplyId
              }
            );
          }),
          revocationReason
        });
        if (res.code == "OK") {
          this.$message.success("销审成功");
          this.rejectedVisible = false;
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$refs.rejectedDialog.dialogLoading = false;
      }
    },
    //点击删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所勾选的数据？", "批量删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteAllocationApplyReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 删除请求
    async deleteAllocationApplyReq() {
      try {
        this.loading = true;
        let res = await deleteAllocationApply({
          idList: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击审核
    handleAudit() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认审核所勾选的数据？", "批量审核", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.examineEngineeringAllocationApplyReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 审核请求
    async examineEngineeringAllocationApplyReq() {
      try {
        this.loading = true;
        let url =
          this.dialogType == 1
            ? examineAllocationApply
            : examineEngineeringAllocationApply;
        let res = await url({
          applyIdList: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("审核成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
